<template>
  <div>
    <h3
      id="kentico-title"
      class="block mb-2"
      :class="{ 'text-center': isStepComplete }"
    >
      {{ heading }}
    </h3>
    <p
      id="kentico-intro"
      v-if="content != ''"
      v-html="parsedContent(content)"
      class="my-4 kentico-content"
      :class="{ 'text-center': isStepComplete }"
    ></p>
    <!-- <h4 class="text-center mb-2" v-if="isStepComplete">
      {{ $t("message.your-impact") }}
    </h4> -->
    <!-- <LivesImpactedPill
      v-if="isStepComplete"
      :text="pillText"
      class="mb-4 mx-auto"
      :class="{
        'order-first': !isStepComplete
      }"
    /> -->
  </div>
</template>
<script>
// import LivesImpactedPill from "@/components/shared/LivesImpactedPill.vue";

export default {
  name: "TaskDonorboxContent",
  props: {
    isStepStart: {
      type: Boolean,
      required: true
    },
    isStepInProgress: {
      type: Boolean,
      required: true
    },
    isStepComplete: {
      type: Boolean,
      required: true
    },
    task: {
      type: Object,
      required: true
    }
  },
  components: {
    // LivesImpactedPill
  },
  computed: {
    heading() {
      if (this.isStepStart || this.isStepInProgress)
        return this.task.introHeading;
      else return this.task.completeHeading;
    },
    content() {
      if (this.isStepStart || this.isStepInProgress)
        return this.task.introContent.html;

      return this.task.completeContent.html;
    },
    pillText() {
      return "hola";
    }
  },
  methods: {
    parsedContent(src) {
      return src.replace(/\n/g, "<br />");
    }
  }
};
</script>
